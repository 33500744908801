import React from "react";
import Head from "next/head";
import {IMetaWrapperProps} from "../types/pages";
import {domainUrl} from "../helper"

export const MetaWrapper = ({
                                dbName = "",
                                title,
                                m_url,
                                m_title,
                                m_desc,
                                m_key,
                                m_img,
                                children
                            }: IMetaWrapperProps) => {

    const url = m_url || dbName
    let databaseName;
    if (dbName.includes(".zegashop.com")) {
        let dataName = dbName.split(".zegashop.com");

        databaseName = dataName[0];
        process.env.domainName = dbName;

        process.env.databaseName = databaseName;
    } else {
        process.env.domainName = dbName;
        databaseName = dbName.split(".")[0];
        if (databaseName == "www") {
            databaseName = dbName.split(".")[1];
        }
        process.env.databaseName = databaseName;
    }

    const newImgCombinedPath = domainUrl(`${dbName}/storage/${m_img}`)
    const logoPath = domainUrl(`${dbName}/storage/${databaseName}/configuration/logo/logo.webp`)
    const sharePic = m_img ? newImgCombinedPath : logoPath

    return (
        <>
            <Head>
                <title>{title}</title>
                <link rel="canonical" href={url}/>
                <meta name="generator" content="Zegashop"/>
                <meta name="title" content={m_title}/>
                <meta name="description" content={m_desc}/>
                <meta name="keywords" content={m_key}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" name="title" content={m_title}/>
                <meta property="og:description" name="description" content={m_desc}/>
                <meta property="og:keywords" name="keywords" content={m_key}/>
                <meta property="og:image" name="image" content={sharePic}/>
                <meta property="og:url" name="url" content={url}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content={`@${dbName}`}/>
                <meta name="twitter:creator" content={`@${dbName}`}/>
                <meta name="twitter:title" content={m_title}/>
                <meta name="twitter:description" content={m_desc}/>
                <meta name="twitter:image" content={sharePic}/>
                <meta name="twitter"/>
            </Head>
            {children}
        </>
    )

}
